<script>
  import { get, call } from 'vuex-pathify'
  import accountApi from '@/api/account'
  import { Survey } from '@/store/account'
  import screen from '@/helpers/screen'
  import shuffle from '@/helpers/shuffle'
  import { events } from '@/helpers/gtm'

  export default {
    props: {
      /** The selected subscription ID. */
      selectedSubscriptionId: {
        type: Number,
        default: undefined,
      },
    },
    data() {
      return {
        Survey,
        formRef: null,
        isSubmitting: false,
        error: null,
        currentStep: this.selectedSubscriptionIsWaitlist ? Survey.CONFIRM : Survey.FORM_TIER_1,
        openAnswer: '',
        tierAnswers: [
          [
            'Financial reasons',
            [
              'My financial situation changed',
              'I don’t get enough value for the price',
              'I would be interested in a “lite” box (less products, less expensive)',
              'If I could pay for my Membership in monthly installments, I would continue',
            ],
          ],
          [
            'Only wanted one box',
            [
              'I tried it because I wanted the whole box',
              'I tried it because I really wanted one product',
              'This box was purchased as a gift',
            ],
          ],
          [
            'Box arrived too late in the season',
            [
              'I will definitely come back if boxes ship at the beginning of the season',
              'I may come back if boxes ship at the beginning of the season',
              'I will not come back even if shipping improves significantly',
            ],
          ],
          [
            'I didn’t like the products',
            [
              'I’m an Annual Member but I missed the customization window, so I got products I didn’t like',
              'I’d keep my Membership if Seasonal Members could customize the box',
              'I would keep my Membership if there were more customization options that I liked',
              'The product quality was worse than expected',
            ],
          ],
          [
            'I received the wrong products',
            [
              'I reached out to customer support (help@alltrue.com) and they didn’t resolve my issue',
              'I didn’t want to go through the hassle of getting the incorrect products replaced',
              'I didn’t know that customer support would send replacements for incorrect products',
            ],
          ],
          [
            'Shipping issues',
            [
              'My main frustration is that the box shipped late',
              'My main frustration is that my Add-On Market order shipped late',
              'My box was shipped to the wrong address',
              'I never received my box',
            ],
          ],
          [
            'Add-On Market issues',
            [
              'I had problems using the website',
              'Prices in the Add-On Market are too high',
              'My Add-On Market order shipped too late',
              'My Add-On Market order arrived damaged',
              'My Add-On Market order had missing products',
            ],
          ],
          ['Switching to another service', []],
          [
            'Planning to subscribe again later',
            [
              'If there was a pause feature in my account, I would use that instead of cancelling',
              'If there was a pause feature in my account, I would still cancel and renew later',
              'I always wait to see what is in the next box before I decide to join',
            ],
          ],
          [
            'I’m moving',
            [
              'I don’t have a new address to ship to yet',
              'I was unable to change my address in my account (our support team would love to help you, please email help@alltrue.com)',
            ],
          ],
        ],
        openAnswers: {
          Other: 'Tell us a bit more',
          'Switching to another service': 'Tell us a bit more',
        },
        firstTierAnswer: '',
        secondTierAnswer: '',
        firstTierAnswersIndex: null,
        secondTierAnswersIndex: null,
        allAnswersForUser: [],
      }
    },
    computed: {
      screen,
      ...get('feature', ['flags']),
      ...get('config', ['currentSeason']),
      ...get('account', [
        'findSubscriptionById',
        'isSubscriptionWaitlist',
        'activeStatusSubscriptions',
      ]),
      isLastSurveyStep() {
        return this.currentStep === Survey.CONFIRMED || this.currentStep === Survey.NEVERMIND
      },
      isCharLimitReached() {
        const openAnswerCharCount =
          this.tierFinalAnswer?.length - `${this.firstTierAnswer}:`?.length
        return openAnswerCharCount > this.textareaMaxCharLimit
      },
      charLimitErrorMessage() {
        return `Please limit your response to ${this.textareaMaxCharLimit} characters. ${this.openAnswer?.length}/${this.textareaMaxCharLimit} characters used.`
      },
      textareaMaxCharLimit() {
        return 255 - `${this.firstTierAnswer}:`?.length
      },
      tierFinalAnswer() {
        if (this.openAnswerPlaceHolder) {
          return `${this.firstTierAnswer}:${this.openAnswer}`
        }
        return `${this.firstTierAnswer}:${this.secondTierAnswer}`
      },
      firstTierAnswers() {
        return this.tierAnswers.map((tierAnswer) => tierAnswer[0])
      },
      surveyAnswers() {
        if (this.currentStep === Survey.FORM_TIER_2) {
          const secondTierAnswers = this.tierAnswers[this.firstTierAnswersIndex][1]
          return secondTierAnswers
        }
        return this.firstTierAnswers
      },
      openAnswerPlaceHolder() {
        if (Object.prototype.hasOwnProperty.call(this.openAnswers, this.firstTierAnswer)) {
          return this.openAnswers[this.firstTierAnswer]
        }
        return undefined
      },
      selectedSubscription() {
        return this.findSubscriptionById(this.selectedSubscriptionId)
      },
      selectedSubscriptionIsWaitlist() {
        return this.isSubscriptionWaitlist(this.selectedSubscription)
      },
      selectedSubscriptionType() {
        return this.selectedSubscription?.type
      },
      cancellationOfferImageSrc() {
        let cancelOfferImageSrc = ''
        switch (this.currentSeason.toLowerCase()) {
          case 'spring':
            cancelOfferImageSrc = '/static/account/cancellation-offer-spring-02.png'
            break
          case 'summer':
            cancelOfferImageSrc = '/static/account/cancellation-offer-summer-02.png'
            break
          case 'winter':
            cancelOfferImageSrc = '/static/account/cancellation-offer-winter-02.png'
            break
          case 'fall':
            cancelOfferImageSrc = '/static/account/cancellation-offer-fall-02.png'
            break
          default:
            cancelOfferImageSrc = '/static/account/cancellation-offer-spring-02.png'
        }
        return cancelOfferImageSrc
      },
      hasAcceptedCancelOffer() {
        for (const answer of this.allAnswersForUser) {
          if (Object.prototype.hasOwnProperty.call(answer, 'x-cancel-offer-accepted')) {
            return true
          }
        }
        return false
      },
    },
    watch: {
      currentStep() {
        this.$emit('surveyStep', this.currentStep)
      },
      isLastSurveyStep() {
        setTimeout(() => {
          this.$navigate({
            name: 'AccountProfileSubscriptions',
          })
        }, 3000)
      },
    },
    async created() {
      // Shuffle here for indexes to store the correct answer
      this.shuffleTierAnswers()
      const result = await accountApi.getAllAnswersForUser()
      this.allAnswersForUser = result.allAnswersForUser
      if (this.hasAcceptedCancelOffer) {
        await this.$store.dispatch('account/fetchRedemptions')
      }
      events.log({
        name: 'started cancellation survey',
        data: {
          plan: this.selectedSubscriptionType,
        },
      })
    },
    methods: {
      ...call('subscribe', ['unsubscribe', 'applyOffer']),
      async submitSurvey() {
        this.isSubmitting = true
        try {
          await this.formRef.process(
            accountApi.setSurveyAnswer({
              key: 'exit-survey-reason',
              value: this.tierFinalAnswer,
            })
          )
        } catch (error) {
          if (error) this.error = true
        } finally {
          this.isSubmitting = false
        }
      },
      async unsubscribeNextStep() {
        this.isSubmitting = true
        try {
          await this.unsubscribe({
            subscriptionId: this.selectedSubscriptionId,
            plan: this.selectedSubscriptionType,
          })
          if (this.tierFinalAnswer) {
            await this.submitSurvey()
          }
          this.currentStep = Survey.CONFIRMED
        } catch (error) {
          if (error) this.error = true
        } finally {
          this.isSubmitting = false
        }
      },
      async applyOfferNextStep() {
        this.isSubmitting = true
        try {
          const { success } = await this.applyOffer({
            subscriptionId: this.selectedSubscriptionId,
            plan: this.selectedSubscriptionType,
          })
          if (success) {
            this.currentStep = Survey.UNSUBSCRIBE_OFFER_CONFIRMED
          } else {
            this.error = true
          }
        } catch (error) {
          if (error) this.error = true
        } finally {
          this.isSubmitting = false
        }
      },
      saveAnswer(index) {
        if (this.currentStep === Survey.FORM_TIER_1) {
          this.firstTierAnswer = this.tierAnswers[index][0]
          this.firstTierAnswersIndex = index
          this.currentStep = Survey.FORM_TIER_2
          events.log({
            name: 'selected first tier answer',
            data: {
              plan: this.selectedSubscriptionType,
            },
          })

          // reset focus for second tier answer
          document.activeElement.blur()
        } else if (this.currentStep === Survey.FORM_TIER_2) {
          this.secondTierAnswer = this.tierAnswers[this.firstTierAnswersIndex][1][index]
          this.secondTierAnswersIndex = index
          this.handleFormTierAnswers()
          events.log({
            name: 'selected second tier answer',
            data: {
              plan: this.selectedSubscriptionType,
            },
          })
        }
      },
      shuffleTierAnswers() {
        // Shuffle second tier
        for (const tierAnswer of this.tierAnswers) {
          const secondTierAnswers = tierAnswer[1]
          shuffle(secondTierAnswers)
          if (secondTierAnswers.length > 1) {
            secondTierAnswers.push('None of the above apply')
          }
        }
        // Shuffle first tier
        shuffle(this.tierAnswers)
        this.tierAnswers.push(['Other', []])
      },
      handleFormTierAnswers() {
        if (
          this.flags['cancel-offer'] &&
          !this.hasAcceptedCancelOffer &&
          this.activeStatusSubscriptions?.length === 1
        ) {
          this.currentStep = Survey.UNSUBSCRIBE_OFFER
          events.log({
            name: 'viewed cancel offer',
            data: {
              plan: this.selectedSubscriptionType,
            },
          })
        } else {
          this.currentStep = Survey.UNSUBSCRIBE_ME
          events.log({
            name: 'viewed confirm cancellation',
            data: {
              plan: this.selectedSubscriptionType,
            },
          })
        }
      },
      handleNevermind() {
        this.currentStep = Survey.NEVERMIND
        events.log({
          name: 'exited cancellation',
          data: {
            plan: this.selectedSubscriptionType,
          },
        })
      },
    },
  }
</script>

<template>
  <div>
    <!-- General error handling -->
    <BasePanelWarning v-if="error" class="mb-6">
      Something went wrong. Please try again and contact us at
      <BaseLinkStyled href="mailto:help@alltrue.com">help@alltrue.com</BaseLinkStyled>
      if the problem persists.
    </BasePanelWarning>
    <div
      :class="`${
        currentStep === Survey.FORM_TIER_1 || currentStep === Survey.FORM_TIER_2 ? '' : 'hidden'
      }`"
    >
      <h2 class="text-heading font-heading font-semibold mb-2 text-center">Cancel my Membership</h2>
      <div class="text-center text-lg leading-close mb-10 md:mb-42px">
        <template v-if="currentStep === Survey.FORM_TIER_2 && firstTierAnswer === 'Other'">
          <div class="-mx-2"
            ><span class="block mx-auto max-w-90"
              >Can you please share a little bit more about your reason for cancelling?</span
            ></div
          >
        </template>
        <template
          v-else-if="
            currentStep === Survey.FORM_TIER_2 && firstTierAnswer === 'Switching to another service'
          "
        >
          Which service are you planning on <br />switching to?
        </template>
        <template v-else-if="currentStep === Survey.FORM_TIER_2 && !openAnswerPlaceHolder">
          Which of the following reasons <br />best applies to your reason for <br />cancelling
          today?
        </template>
        <template v-else> Please tell us why you are <br />cancelling your Membership </template>
      </div>
      <BaseForm v-ref="formRef" class="form-max-width" @submit="submitSurvey">
        <BaseStack gap-size="none">
          <div v-for="(surveyAnswer, index) in surveyAnswers" :key="index">
            <button
              type="button"
              class="flex justify-between align-center w-full"
              @click="saveAnswer(index)"
            >
              <p>{{ surveyAnswer }}</p>
              <div class="flex-none inline-block w-6 h-6">
                <IconArrowRight />
              </div>
            </button>
            <BaseDivider class="my-5 md:my-4" color="dawn-dk2" />
          </div>
          <div v-if="openAnswerPlaceHolder">
            <div class="flex justify-between align-center w-full mb-4">
              <BaseInput
                v-model="openAnswer"
                class="other-textarea w-full"
                type="textarea"
                :required="false"
                input-classes="w-full border"
                :placeholder="openAnswerPlaceHolder"
                :rules="{ max: textareaMaxCharLimit }"
                :custom-messages="{ max: charLimitErrorMessage }"
              />
            </div>
          </div>
        </BaseStack>
      </BaseForm>

      <template v-if="openAnswerPlaceHolder">
        <BaseButton
          type="button"
          :disabled="isCharLimitReached"
          class="w-full md:max-w-86 mx-auto"
          @click="handleFormTierAnswers"
        >
          Next
        </BaseButton>
        <BaseDivider class="form-max-width my-4" color="dawn-dk2" />
      </template>

      <div :class="`flex justify-center ${openAnswerPlaceHolder ? '' : 'mt-5 md:mt-6'}`">
        <BaseLinkStyled class="text-center text-md leading-close" @click="handleNevermind">
          <template v-if="selectedSubscriptionIsWaitlist">
            Never mind, I want to stay on <br />
            the waitlist reserve
          </template>
          <template v-else>
            Never mind, I want to keep <br />
            my Membership
          </template>
        </BaseLinkStyled>
      </div>
    </div>
    <template v-if="currentStep === Survey.UNSUBSCRIBE_ME">
      <h2 class="text-heading font-heading font-semibold mb-2 w-full max-w-80 text-center mx-auto"
        >We appreciate your feedback!</h2
      >
      <div
        class="text-center text-lg md:text-base leading-close mb-35 md:mb-10 max-w-88 md:max-w-99 mx-auto"
        >Click “Unsubscribe me” below to end your Alltrue Membership or if you’ve changed your mind,
        click “Never mind, I want to keep my Membership”.</div
      >
      <BaseButton
        class="mb-12 md:mb-10 w-full max-w-72 md:max-w-86 mx-auto"
        @click="currentStep = Survey.CONFIRM"
      >
        Unsubscribe me
      </BaseButton>
      <div class="flex justify-center">
        <BaseLinkStyled class="text-center text-md leading-close" @click="handleNevermind">
          <template v-if="selectedSubscriptionIsWaitlist">
            Never mind, I want to stay on <br />
            the waitlist reserve
          </template>
          <template v-else>
            Never mind, I want to keep <br />
            my Membership
          </template>
        </BaseLinkStyled>
      </div>
    </template>
    <template v-if="currentStep === Survey.UNSUBSCRIBE_OFFER">
      <div class="text-center text-lg leading-close mb-2">Before you go,</div>
      <h2
        v-if="selectedSubscriptionType === 'quarterly'"
        class="text-heading font-heading font-semibold mt-0 mb-2 w-full max-w-80 text-center mx-auto"
        >Get $10 off your next 3 boxes!</h2
      >
      <h2
        v-else
        class="text-heading font-heading font-semibold mt-0 mb-2 w-full max-w-80 text-center mx-auto"
        >Get $40 off your next renewal!</h2
      >
      <div class="text-center italic text-sm leading-close mb-10">This is our best offer ever</div>
      <div class="mb-67px">
        <BaseImage
          :src="cancellationOfferImageSrc"
          alt=""
          background-transparent
          responsive="md"
          class="mx-auto"
          style="max-width: 239px"
        />
      </div>
      <BaseButton
        class="mb-8 md:mb-6 w-full max-w-72 md:max-w-86 mx-auto"
        @click="applyOfferNextStep"
      >
        Redeem Offer
      </BaseButton>
      <div class="flex justify-center">
        <BaseLinkStyled
          class="text-center text-md leading-close"
          @click="currentStep = Survey.CONFIRM"
        >
          Cancel my subscription
        </BaseLinkStyled>
      </div>
    </template>
    <template v-if="currentStep === Survey.UNSUBSCRIBE_OFFER_CONFIRMED">
      <h2 class="text-heading font-heading font-semibold mb-2 w-full max-w-80 text-center mx-auto"
        >Offer Confirmed!</h2
      >
      <div
        class="text-center text-lg md:text-base leading-close mb-72 md:mb-10 max-w-88 md:max-w-99 mx-auto"
        >{{
          selectedSubscriptionType === 'quarterly'
            ? 'We will automatically apply your $10 credit to your next 3 boxes.'
            : 'We will automatically apply your $40 credit to your next renewal.'
        }}
        We’re thrilled to continue our journey together at Alltrue.</div
      >
      <BaseButton
        class="mb-12 md:mb-10 w-full max-w-72 md:max-w-86 mx-auto"
        @click="
          $navigate({
            name: 'AccountProfileSubscriptions',
          })
        "
      >
        Okay
      </BaseButton>
    </template>
    <template v-if="currentStep === Survey.CONFIRM">
      <h2 class="text-heading font-heading font-semibold mb-2 w-full max-w-80 text-center mx-auto"
        >Confirm Cancellation</h2
      >
      <div
        class="text-center text-lg md:text-base leading-close mb-75px md:mb-10 max-w-85 md:max-w-96 mx-auto"
      >
        <template v-if="selectedSubscriptionIsWaitlist">
          <p>
            By canceling your spot on the Alltrue waitlist reserve, you will not be charged for the
            price of the box once it becomes available. You will also lose your guaranteed place in
            line to receive our next box. You may
            <span class="inline-block">reactivate anytime</span>.
          </p>
          <p> If you are sure you’d like to cancel, please click ‘Confirm Cancellation’. </p>
        </template>
        <template v-else>
          <p>
            By canceling your Membership with Alltrue, you are simply preventing it from renewing on
            its next renewal date. If you are an Annual Member and cancel part-way through your
            term, your Membership will remain live until your next billing date, at which point it
            will terminate and not renew for another year.
          </p>
          <p>
            If you are a Seasonal Member your Membership will terminate and not renew upon next
            billing date.
            <BaseLinkStyled
              href="https://faq.alltrue.com/hc/en-us/articles/360007367754-What-is-your-cancellation-policy-"
            >
              Learn More
            </BaseLinkStyled>
          </p>
          <p>
            If you are sure you'd like to cancel, please click
            {{ screen.md ? 'Confirm Cancellation' : 'Confirm' }} below.
          </p>
        </template>
      </div>
      <BaseButton class="mb-12 md:mb-10 max-w-72 md:max-w-86 mx-auto" @click="unsubscribeNextStep">
        Confirm Cancellation
      </BaseButton>
      <div class="flex justify-center">
        <BaseLinkStyled class="text-center text-md leading-close" @click="handleNevermind">
          <template v-if="selectedSubscriptionIsWaitlist">
            Never mind, I want to stay on <br />
            the waitlist reserve
          </template>
          <template v-else>
            Never mind, I want to keep <br />
            my Membership
          </template>
        </BaseLinkStyled>
      </div>
    </template>
    <div :class="`${currentStep === Survey.CONFIRMED ? '' : 'hidden'}`">
      <h2
        v-if="selectedSubscriptionIsWaitlist"
        class="text-heading font-heading font-semibold mb-2 w-full text-center mx-auto"
        >You've been <br v-if="!screen.md" />removed from <br v-if="screen.md" />the
        <br v-if="!screen.md" />waitlist reserve.</h2
      >
      <h2
        v-else
        class="text-heading font-heading font-semibold mb-2 w-full max-w-60 md:max-w-80 text-center mx-auto"
        >You’ve been unsubscribed from Alltrue.</h2
      >
      <div class="text-center text-lg md:text-base leading-close w-full mx-auto mt-0 mb-6 md:mb-8"
        >We’re sad to see you go <br v-if="!screen.md" />and we hope you come <br />back soon!</div
      >
      <BaseImage
        src="/static/account/cancellation-unsubscribed.png"
        alt=""
        background-transparent
        responsive="md"
        class="mx-auto"
        style="max-width: 322px"
      />
    </div>
    <div :class="`${currentStep === Survey.NEVERMIND ? '' : 'hidden'}`">
      <h2
        v-if="selectedSubscriptionIsWaitlist"
        class="text-heading font-heading font-semibold mb-6 md:mb-8 w-full text-center mx-auto"
        >Awesome! <br />We can't wait to <br v-if="!screen.md" />send you our next box <br />when it
        becomes available.</h2
      >
      <h2
        v-else
        class="text-heading font-heading font-semibold text-center w-full max-w-80 mx-auto mb-16 md:mb-8"
        >Awesome! <br v-if="screen.md" />We are thrilled to continue our journey together.</h2
      >
      <BaseImage
        src="/static/account/cancellation-nevermind.png"
        alt=""
        background-transparent
        responsive="md"
        class="mx-auto max-w-40"
      />
    </div>
    <BaseSpinner v-if="isSubmitting" overlay="fixed" overlay-color="white" class="z-10" />
  </div>
</template>

<style lang="postcss" scoped>
  /* purgecss start ignore */
  .form-max-width {
    @screen md {
      max-width: 343px;

      @apply mx-auto w-full;
    }
  }

  .other-textarea >>> textarea {
    height: 200px;
    border-radius: 4px;
    @apply border-white bg-white;

    &::placeholder {
      @apply text-gray-opacity68;
    }
  }
  /* purgecss end ignore */
</style>
