<script>
  import { get, call } from 'vuex-pathify'
  import AccountSubscriptionCancellation from '@/components/account/AccountSubscriptionCancellation'
  import LogoAlltrue from '@/images/logos/LogoAlltrue.svg'
  import { Survey } from '@/store/account'
  import { PLAN_PRICE } from '@/helpers/constants'
  import formatPrice from '@/helpers/formatPrice'
  import screen from '@/helpers/screen'
  import { getRecurlyErrorMessage } from '@/helpers/recurly'
  import { events } from '@/helpers/gtm'

  export default {
    components: {
      AccountSubscriptionCancellation,
      LogoAlltrue,
    },
    props: {
      /** Which action was clicked. */
      clickedAction: {
        type: String,
        default: undefined,
        validator: (value) => ['upgrade', 'reactivate', 'unsubscribe'].includes(value),
      },
      /** The selected subscription ID. */
      selectedSubscriptionId: {
        type: Number,
        default: undefined,
      },
    },
    data() {
      return {
        submitting: false,
        isConfirmed: false,
        error: null,
        errorResponse: null,
        isArrowVisible: true,
        PLAN_PRICE,
      }
    },
    computed: {
      ...get('config', ['currentSeason']),
      ...get('account', ['subscriptions', 'findSubscriptionById']),
      ...get('general', ['isUnsubscribeAction', 'isReactivateAction']),
      screen,
      selectedSubscription() {
        return this.findSubscriptionById(this.selectedSubscriptionId)
      },
      clickedActionHeader() {
        switch (this.clickedAction) {
          case 'upgrade':
            return `Yay! You are about to upgrade to an Annual Membership and gain access to box customization, and save $20 per year.`
          default:
            return ''
        }
      },
      confirmedActionHeader() {
        switch (this.clickedAction) {
          case 'upgrade':
            return `Start customizing your box!`
          default:
            return ''
        }
      },
      clickedActionButtonText() {
        switch (this.clickedAction) {
          case 'upgrade':
            return `Yes, upgrade me!`
          default:
            return ''
        }
      },
      upgradeCost() {
        return this.upgradeSeasons === 3
          ? formatPrice(PLAN_PRICE.annually - PLAN_PRICE.quarterly)
          : formatPrice(PLAN_PRICE.annually)
      },
      upgradeSeasons() {
        return this.selectedSubscription?.upgradeSeasons
      },
      upgradeActionConfirmed() {
        return this.isConfirmed && this.clickedAction === 'upgrade'
      },
      recurlyErrorMessage() {
        return getRecurlyErrorMessage(this.errorResponse)
      },
    },
    watch: {
      isConfirmed() {
        if (this.isConfirmed && this.isReactivateAction) {
          setTimeout(() => {
            this.$navigate({
              name: 'AccountProfileSubscriptions',
            })
          }, 3000)
          this.isArrowVisible = false
        } else {
          this.isArrowVisible = true
        }
      },
    },
    methods: {
      ...call('subscribe', ['upgradeSubscription', 'reactivateSubscription']),
      dismiss() {
        this.$navigate({
          name: 'AccountProfileSubscriptions',
        })
      },
      redirectToMyBox() {
        this.$navigate({
          name: 'CustomizeStart',
        })
      },
      async confirm(action) {
        this.submitting = true
        try {
          if (action === 'upgrade') {
            await this.upgradeSubscription({ subscriptionId: this.selectedSubscriptionId })
          } else if (action === 'reactivate') {
            await this.reactivateSubscription({ subscriptionId: this.selectedSubscriptionId })
          }
          this.isConfirmed = true
        } catch (error) {
          if (error) {
            this.error = true
            this.errorResponse = error.data
          }
        } finally {
          this.submitting = false
        }
      },
      handleSurveyStep(value) {
        if (
          value === Survey.CONFIRMED ||
          value === Survey.NEVERMIND ||
          value === Survey.UNSUBSCRIBE_OFFER_CONFIRMED
        ) {
          this.isArrowVisible = false
        } else {
          this.isArrowVisible = true
        }
      },
      handleArrowLeftClicked() {
        if (this.isUnsubscribeAction) {
          this.sendUnsubscribeExitedEvent()
        }
        this.$navigate({
          name: 'AccountProfileSubscriptions',
        })
      },
      sendUnsubscribeExitedEvent() {
        events.log({
          name: 'exited cancellation',
          data: {
            plan: this.selectedSubscription?.type,
          },
        })
      },
    },
  }
</script>

<template>
  <div :class="`${isReactivateAction ? '-mt-6 md:mt-0' : ''}`">
    <div
      v-if="isReactivateAction"
      class="center-logo flex items-center mt-5 md:-mt-46px mb-10 md:mb-8 md:justify-center"
    >
      <BaseLinkStyled
        v-if="!screen.md"
        color="black"
        font-size="xs"
        icon-position="left"
        :icon-size="5"
        :to="{ name: 'AccountProfileSubscriptions' }"
        :class="`flex-1 ${isArrowVisible ? '' : 'invisible'}`"
      >
        <template v-slot:icon>
          <IconArrowLeft />
        </template>
      </BaseLinkStyled>
      <LogoAlltrue class="w-75px h-18px md:65px md:h-4" />
    </div>
    <BaseLinkStyled
      v-else-if="!screen.md && !isConfirmed"
      color="black"
      font-size="xs"
      icon-position="left"
      :icon-size="5"
      class="mt-4 mb-4"
      @click="handleArrowLeftClicked"
    >
      <template v-slot:icon>
        <IconArrowLeft />
      </template>
    </BaseLinkStyled>

    <!-- Recurly error handling -->
    <BasePanelWarning v-if="error && Boolean(recurlyErrorMessage)" class="my-3">
      {{ recurlyErrorMessage }}
    </BasePanelWarning>
    <!-- General error handling -->
    <BasePanelWarning v-else-if="error" class="my-3">
      Something went wrong. Please try again and contact us at
      <BaseLinkStyled href="mailto:help@alltrue.com">help@alltrue.com</BaseLinkStyled>
      if the problem persists.
    </BasePanelWarning>

    <!-- Copy for modal: upgrade, reactivate, unsubscribe -->
    <BaseRichText class="mb-10">
      <div
        v-if="upgradeActionConfirmed"
        class="font-semibold uppercase md:text-center text-md mb-2 leading-snug-tight"
        >You've been upgraded</div
      >
      <BaseHeading
        v-if="!isUnsubscribeAction && !isReactivateAction"
        size="h2-lg"
        :class="`capitalize ${upgradeActionConfirmed ? 'mb-6' : 'mb-4'}`"
        >{{ isConfirmed ? confirmedActionHeader : clickedActionHeader }}</BaseHeading
      >
      <BaseDivider v-if="upgradeActionConfirmed" class="mb-4" color="gray-opacity68" :weight="1" />
      <!------- Upgrade copy ------->
      <template v-if="clickedAction === 'upgrade'">
        <div v-if="!isConfirmed">
          <p>
            We will terminate your existing Seasonal Membership and charge you
            {{ upgradeCost }} for the remaining {{ upgradeSeasons }} quarters on your new Annual
            Membership*. As soon as the upgrade process is complete, you’ll see a newly created
            Annual Membership in the “My Memberships" section of your account page.
          </p>
          <p
            >If you have any questions please review this
            <BaseLinkStyled
              href="//faq.alltrue.com/hc/en-us/articles/360023531053-How-do-I-upgrade-my-Quarterly-Subscription-to-an-Annual-Subscription-"
              inline
            >
              FAQ
            </BaseLinkStyled>
            or reach out to our customer care team at:
            <BaseLinkStyled href="mailto:help@alltrue.com">help@alltrue.com</BaseLinkStyled>
          </p>
          <p
            >*Plus applicable shipping &amp; handling to Canada, AK, HI, US Territories. Applicable
            sales taxes will be applied upon submission.
          </p>
        </div>

        <div v-else-if="isConfirmed">
          <p class="font-semibold text-sm leading-snug-tight"
            >Important details regarding your upgraded Membership:</p
          >
          <p style="margin-top: 6px">
            If you are a renewing Member upgrading your existing Seasonal Membership (and therefore
            have yet to make your renewal payment for the {{ currentSeason }} season), we just
            terminated your Seasonal Membership and charged you for the next 4 quarters (including
            the {{ currentSeason }} season) that will make up the remainder of your upgraded Annual
            Membership*.
            <br />
            If you already renewed for the {{ currentSeason }} season or if you recently purchased a
            {{ currentSeason }} Box, we just terminated your Seasonal Membership and charged you for
            the 3 quarters that will follow this {{ currentSeason }} season and which will make up
            the remainder of your upgraded Annual Membership**.
          </p>
          <p>
            If you have any questions, please review this
            <BaseLinkStyled
              href="//faq.alltrue.com/hc/en-us/articles/360023531053-How-do-I-upgrade-my-Quarterly-Subscription-to-an-Annual-Subscription-"
              inline
            >
              FAQ
            </BaseLinkStyled>
            or reach out to our customer care team at:
            <BaseLinkStyled href="mailto:help@alltrue.com">help@alltrue.com</BaseLinkStyled>
          </p>
          <p>
            * {{ $formatPrice(PLAN_PRICE.annually) }} + plus sales taxes and/or $31.80 for shipping
            to Canada, Alaska, Hawaii, US Territories. Note: sales taxes and shipping charges were
            applied at time of upgrade depending on your recipient address!
            <br />
            ** {{ $formatPrice(PLAN_PRICE.annually - PLAN_PRICE.quarterly) }} + plus sales taxes
            and/or $23.85 for shipping to Canada, Alaska, Hawaii, US Territories. Note: sales taxes
            and shipping charges were applied at time of upgrade depending on your recipient
            address!
            <br />
            Note: If you had existing account credits, your upgrade price was deducted by your
            credit balance
          </p>
        </div>
      </template>

      <!------- Reactivate copy ------->
      <!------- Templates based off of AccountSubscriptionCancellation.vue ------->
      <!------- TODO: this can use some refactoring ------->
      <template v-if="isReactivateAction">
        <div v-if="!isConfirmed">
          <h2
            class="text-heading font-heading font-semibold mb-2 w-full md:max-w-80 text-center mx-auto"
            >Yay! Welcome back.</h2
          >
          <div
            class="text-center text-lg md:text-base leading-close mb-75px md:mb-10 max-w-84 md:max-w-99 mx-auto"
            >Your Membership will renew automatically on a quarterly basis using the payment method
            associated with your account. Memberships will auto-renew at the full regular price,
            plus any applicable shipping charges and taxes. To prevent renewal, you can cancel your
            Membership online or by emailing us at
            <BaseLinkStyled href="mailto:help@alltrue.com">help@alltrue.com</BaseLinkStyled>. All
            prices listed are in USD. By clicking “Yes, reactivate my Membership!” you are agreeing
            to the Alltrue
            <BaseLinkStyled :to="{ name: 'PageTermsOfUse' }" target="_blank" inline>
              Terms of Use
            </BaseLinkStyled>
            and
            <BaseLinkStyled :to="{ name: 'PagePrivacy' }" target="_blank" inline>
              Privacy Policy
            </BaseLinkStyled>
            and acknowledge the above renewal terms.</div
          >
          <BaseButton
            class="mb-12 md:mb-10 w-full max-w-86 mx-auto"
            @click="confirm(clickedAction)"
          >
            Yes, reactivate my Membership!
          </BaseButton>
          <div class="flex justify-center">
            <BaseLinkStyled class="text-center text-md leading-close" @click="dismiss">
              Never mind
            </BaseLinkStyled>
          </div>
        </div>

        <div v-else-if="isConfirmed">
          <h2 class="text-heading font-heading font-semibold mb-2 w-full text-center mx-auto"
            >Cheers! Your <br v-if="!screen.md" />Membership <br v-if="screen.md" />has been
            <br v-if="!screen.md" />reactivated.</h2
          >
          <div
            class="text-center text-lg md:text-base leading-close w-full max-w-75 md:max-w-100 mx-auto mt-0 mb-6 md:mb-8"
            >We’re so glad to have you back. If you have any questions about your Membership, please
            reach out to our customer care team at
            <BaseLinkStyled href="mailto:help@alltrue.com"> help@alltrue.com </BaseLinkStyled></div
          >
          <BaseImage
            src="/static/account/cancellation-nevermind.png"
            alt=""
            background-transparent
            responsive="md"
            class="mx-auto max-w-40"
          />
        </div>
      </template>

      <!------- Unsubscribe copy ------->
      <AccountSubscriptionCancellation
        v-if="isUnsubscribeAction"
        :selected-subscription-id="selectedSubscriptionId"
        @surveyStep="handleSurveyStep"
      />
    </BaseRichText>
    <BaseButton v-if="upgradeActionConfirmed" @click="redirectToMyBox">{{
      'Continue to Customization'
    }}</BaseButton>
    <BaseButton
      v-else-if="!isUnsubscribeAction && !isReactivateAction"
      @click="isConfirmed ? dismiss() : confirm(clickedAction)"
      >{{ isConfirmed ? 'Close' : clickedActionButtonText }}</BaseButton
    >

    <!-- Loading spinner -->
    <BaseSpinner
      v-if="submitting"
      :overlay="`${isReactivateAction ? 'fixed' : 'absolute'}`"
      overlay-color="white"
      class="z-10"
    />
  </div>
</template>

<style lang="postcss" scoped>
  /* purgecss start ignore */
  .center-logo {
    &::after {
      display: inline-block;
      flex: 1;
      content: '';

      @screen md {
        display: none;
      }
    }
  }

  >>> .text-heading {
    font-size: 36px;
    line-height: 1.05;
  }
  /* purgecss end ignore */
</style>
